//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/v3'
import { copy } from '@/utils/Common'
import { teamsStore } from '@/store'
import { transformEntityName } from '@/utils'
import { TdBtn } from 'td-ui'
import PicQR from './PicQR.vue'

export default {
  name: 'TabQRCode',
  components: {
    TdBtn,
    PicQR,
    IconTrash: () => import('@tada/icons/dist/IconTrash2M.vue'),
  },
  data () {
    return {
      invitations: null,
    }
  },
  computed: {
    invitationData () {
      if (!this.invitations || this.invitations.length === 0) return null
      const invite = this.invitations[0]

      return {
        qr: invite.qr,
        link: `${window.location.origin}/api/v3/join/${invite.token}`,
      }
    },
  },
  async created () {
    try {
      this.invitations = await api.invitations.getAll()
    } catch (e) {
      // TODO: need actual error handling
      this.invitations = []
    }
  },
  methods: {
    copy (text) {
      copy(text)
    },
    async createInvitation () {
      try {
        this.invitations = null
        await api.invitations.create()
        this.invitations = await api.invitations.getAll()
      } catch (e) {
        this.invitations = []
      }
    },
    async deleteInvitation () {
      try {
        const uid = this.invitations[0] && this.invitations[0].uid
        this.invitations = null
        await api.invitations.delete(uid)
        this.invitations = []
      } catch (e) {
        this.invitations = await api.invitations.getAll()
      }
    },
    print () {
      const win = window.open('about:blank', '_new')
      win.document.open()
      win.document.write(`
        <html><head><scri${''}pt>
          function main () {
            window.setTimeout(() => {
              window.print()
              window.close()
            }, 10)
          }
        </scri${''}pt></head></html>
        <body onload="main()">
          <img src="${this.invitationData.qr}" style="width: 670px; height: 670px; margin: 0 auto;">
          <div style="text-align: center; font-size: 24px;">
            Возпользуйтесь этим QR-кодом, чтобы присоединиться к команде ${transformEntityName(teamsStore.getters.currentTeam.name)}
          </div>
        </body>
      `)
      win.document.close()
    },
  },
}
