































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































export default {
  name: 'PicQR',
}
